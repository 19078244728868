import { useHandleRealHeight } from "@ui/utils/use-handle-real-height";
import { Button } from "@uxf/ui/button";
import { Icon } from "@uxf/ui/icon";
import { RasterImage } from "@uxf/ui/raster-image";
import { FC, useRef, useState } from "react";

const DATA_MOBILE = [
    {
        step: 1,
        headline: "Předplaťte si jídlo tam, kde mají rádi hosty",
        text: "Je to jako ve školní jídelně, ale vyberete si z\xa0různých restaurací, jídel a\xa0speciálních nabídek.",
        image: "/images/intro/step-1.svg",
    },
    {
        step: 2,
        headline: "Získáte něco navíc",
        text: "Jídlo předplacené přes Předplátko může být levnější, větší, mít něco navíc nebo nebýt v\xa0běžné nabídce.",
        image: "/images/intro/step-2.svg",
    },
    {
        step: 3,
        headline: "Nikdo vám jídlo již nevyfoukne",
        text: "Největší zklamání je, když si nemůžete dát to, na co jste se těšili. To se již nestane!",
        image: "/images/intro/step-3.svg",
    },
    {
        step: 4,
        headline: "Ukažte QR kód na mobilu a\xa0je\xa0hotovo",
        text: "Žádné zdržování výběrem ani placením za jídlo. Stačí ukázat váš QR kód a\xa0jídlo je na\xa0stole.",
        image: "/images/intro/step-4.svg",
    },
];

const DATA_DESKTOP = [DATA_MOBILE[1], DATA_MOBILE[2], DATA_MOBILE[3]];

interface Props {
    onComplete: () => void;
}

export const Intro: FC<Props> = (props) => {
    const [step, setStep] = useState(1);
    const dataByStep = DATA_MOBILE[step - 1];

    const previousStep = () => {
        if (step === 1) {
            return;
        }

        setStep((currentStep) => --currentStep);
    };

    const nextStep = () => {
        if (step === DATA_MOBILE.length) {
            props.onComplete();
            return;
        }

        setStep((currentStep) => ++currentStep);
    };

    const ref = useRef<HTMLDivElement>(null);

    useHandleRealHeight(ref, "minHeight");

    return (
        <>
            <div
                className="mx-auto flex min-h-screen flex-col p-6 text-center sm:max-h-[700px] sm:max-w-[344px] md:hidden"
                ref={ref}
            >
                <div className="mb-4 grid grow grid-cols-4 gap-x-1">
                    {DATA_MOBILE.map((item) => (
                        <div
                            key={item.step}
                            className={`h-2 rounded ${item.step === step ? "bg-primary" : "bg-gray-200"}`}
                        />
                    ))}
                </div>
                <RasterImage
                    alt=""
                    className="mx-auto mb-2 w-96 max-w-full"
                    height={275}
                    src={dataByStep.image}
                    width={320}
                />
                <h4 className="uxf-typo-h4 mb-4">{dataByStep.headline}</h4>
                <p className="uxf-typo-body text-lightMedium">{dataByStep.text}</p>
                <div className="mt-4 flex grow items-end">
                    {step > 1 && (
                        <Button
                            isIconButton
                            className="mr-4"
                            variant="white"
                            onClick={previousStep}
                            title="Předchozí"
                            size="lg"
                        >
                            <Icon name="arrow-left" size={16} />
                        </Button>
                    )}
                    <Button className="grow" onClick={nextStep} size="lg">
                        Pokračovat
                    </Button>
                </div>
            </div>
            <div className="mx-auto hidden h-full max-w-5xl py-6 text-center md:block xl:py-16">
                <h1 className="uxf-typo-h4">Předplaťte si jídlo tam, kde mají rádi hosty</h1>
                <p className="uxf-typo-body mx-auto mt-4 max-w-sm text-lightMedium">
                    Je to jako ve školní jídelně, ale vyberete si z&nbsp;různých restaurací, jídel a&nbsp;speciálních
                    nabídek.
                </p>
                <div className="mb-6 mt-10 grid grid-cols-3 gap-x-8 xl:mb-16 xl:mt-20">
                    {DATA_DESKTOP.map((item) => (
                        <div key={item.headline}>
                            <RasterImage
                                alt=""
                                className="mx-auto mb-6 w-48"
                                height={275}
                                src={item.image}
                                width={320}
                            />
                            <h4 className="uxf-typo-h5 mb-4">{item.headline}</h4>
                            <p className="uxf-typo-body text-lightMedium">{item.text}</p>
                        </div>
                    ))}
                </div>
                <Button size="xl" className="w-full max-w-sm" onClick={props.onComplete}>
                    Vybrat restauraci
                </Button>
            </div>
        </>
    );
};
